import * as React from 'react';
import cn from 'classnames';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import RadioTabs from 'styleguide/components/Formik/RadioTabs';
import Textarea from 'styleguide/components/Formik/Textarea/Textarea';
import { FileConcern } from './types';
import Tooltip from 'styleguide/components/Tooltip/Tooltip';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import FieldWrapper from 'styleguide/components/Formik/FieldWrapper/FieldWrapper';
import { Field, FormikValues, useFormikContext } from 'formik';
import RadioTab from 'styleguide/components/Formik/RadioTabs/RadioTab';

export interface Props {
  index: number;
  fileConcern: FileConcern;
}

const ProofApprovalFileConcern = ({ index, fileConcern }: Props) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<FormikValues>();

  return (
    <Grid.Row className={css.fileConcern}>
      <Grid.Col sm={12}>
        <Grid.Row>
          <Grid.Col sm={12}>
            <div className={css.concernContainer}>
              <div>
                {index + 1}. {fileConcern.message}{' '}
              </div>
              {!!fileConcern.detailedMessage && (
                <Tooltip
                  type="question"
                  position="top"
                  message="More info"
                  contentToolTip={<HtmlContent content={fileConcern.detailedMessage} />}
                  className={cn(css.tootltip)}
                />
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className={css.fileConcernForm}>
          <Grid.Col md={4} sm={12}>
            <FieldWrapper
              Input={
                <RadioTabs
                  className={css.tabs}
                  name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                  errors={errors}
                  touched={touched}
                >
                  <Field
                    name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                    label="Approve"
                    checked={values.proofApprovalFileConcernsAttributes[index].approved === true}
                    value
                    onChange={() => {
                      setFieldValue(`proofApprovalFileConcernsAttributes.${index}.approved`, true);
                      setFieldValue(`proofApprovalFileConcernsAttributes.${index}.customerResponse`, '');
                    }}
                    component={RadioTab}
                  />
                  <Field
                    name={`proofApprovalFileConcernsAttributes.${index}.approved`}
                    label="Reject"
                    checked={values.proofApprovalFileConcernsAttributes[index].approved === false}
                    value={false}
                    onChange={() => {
                      setFieldValue(`proofApprovalFileConcernsAttributes.${index}.approved`, false);
                    }}
                    component={RadioTab}
                  />
                </RadioTabs>
              }
            />
          </Grid.Col>
          <Grid.Col md={8} sm={12}>
            <Field
              name={`proofApprovalFileConcernsAttributes.${index}.customerResponse`}
              disabled={values.proofApprovalFileConcernsAttributes[index].approved === true}
              rows={5}
              component={Textarea}
              label="Comments"
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default ProofApprovalFileConcern;
