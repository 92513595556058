import * as React from 'react';
import cn from 'classnames';
import css from './ProofApprovalsPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import { OrderReservation } from './types';
import CustomerServiceContact from './CustomerServiceContact';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import NavigationButtons from 'bundles/App/pages/ProofApprovalsPage/NavigationButtons';

export interface Props {
  orderReservation: OrderReservation;
}

const Rejected = ({ orderReservation }: Props) => (
  <>
    <Grid.Row>
      <Grid.Col>
        <Span className={cn(css.title, css.rejected)}>Proof Rejected</Span>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <HtmlContent
          className={css.bodyText}
          content="It looks like you want to make some changes to your order. Since your proof was rejected, your delivery date will be pushed back. Your customer service representative will be reaching out to discuss next steps!"
        />
        <HtmlContent
          content={"If you'd like to reach your service representative immediately, please contact:"}
        />
        <CustomerServiceContact orderReservation={orderReservation} />
      </Grid.Col>
    </Grid.Row>
    <NavigationButtons />
  </>
);

export default Rejected;
